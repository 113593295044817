<template>
  <v-container>
    <CreateAppointmentDialog ref="createAppointmentDialog" @reload="getCases" />
    <DischargeDialog ref="dischargeDialog" @discharge="getCases"/>
    <h1>Compliance Cases</h1>
    <div v-if="casesCount === 0" class="mt-2">
      <p>There are currently no compliance cases matching the search or location</p>
      <v-btn depressed color="primary" href="/cases/active">
        All Cases
      </v-btn>
    </div>
    <div>
    <v-data-table
      :items="cases"
      :headers="headers"
      @click:row="goToCase($event.id)"
      :server-items-length="casesCount"
      @update:options="getCases"
      :footer-props="{
      'items-per-page-options': [25, 50, 100]
    }"
    >
      <template v-slot:top>
        <search-bar v-on:child-method="updateSearchInput"/>
      </template>
      <template #item.status="{item}">
        {{capitalizeFirstWord(item.status)}}
      </template>
      <template #item.patient_id="{ item }">
        {{ item.patient.first_name }} {{ item.patient.last_name }}
      </template>
      <template #item.location_id="{ item }">
        {{ item.location.name }}
      </template>

      <template #item.nextAppointment="{ item }">
        <span v-if="item.appointments.length > 0">{{ formatCarbonDateNoSetTime(item.appointments[0].date) }}</span>
        <span v-else><v-icon color="warning">fas fa-exclamation-circle</v-icon> no appointment set</span>
      </template>

      <template #item.created_at="{ item }">
        {{ formatDate(item.created_at) }}
      </template>

      <template #item.actions="{ item }">
        <v-menu>
          <template #activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>fas fa-ellipsis-h</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="$refs.createAppointmentDialog.open(item.id, item.status)">
              <v-list-item-title>Schedule New Appointment</v-list-item-title>
            </v-list-item>

            <v-list-item @click="$refs.dischargeDialog.open(item.id)">
              <v-list-item-title class="red--text">Discharge</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    </div>
  </v-container>
</template>
<script>
import CreateAppointmentDialog from '@/components/Case/Dialogs/Appointments/CreateAppointmentDialog.vue'
import SearchBar from '../../components/FormFields/SearchBar'
import DateMixin from '../../mixins/DateMixin'
import DischargeDialog from '@/components/Case/DischargeDialog.vue'
import GetCasesMixin from '@/mixins/GetCasesMixin'
import { formatCarbonDateNoSetTime } from '@/js/PatientIntake/functions'

export default {
  name: 'ComplianceCases',
  components: { DischargeDialog, CreateAppointmentDialog, SearchBar },
  mixins: [DateMixin, GetCasesMixin],
  data: () => ({
    formatCarbonDateNoSetTime,
    headers: [
      { text: 'Status', value: 'status' },
      { text: 'Patient Name', value: 'patient_id' },
      { text: 'Location', value: 'location_id' },
      { text: 'Last Appointment', value: 'nextAppointment', sortable: false },
      { text: 'Quick Actions', value: 'actions', sortable: false }
    ],
    filterParams: {
      status: 'compliance',
      page: '',
      limit: '',
      orderBy: '',
      orderDir: '',
      patient: true,
      location: true,
      scheduledAppointments: true,
      activeLocationId: '',
      search: ''
    }
  }),
  methods: {}
}
</script>
